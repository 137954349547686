<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_schedule')"
                        :isNewButton="checkPermission('courseschedule_store')"
                        :isColumns="true"
                        :isImportButton="true"
                        @new-button-click="showModal('create')"
                        @filter-div-status="datatable.filterStatus = $event"
                        @import-button-click="importData"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            v-bind:key="key"
                        >
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_schedule')"
                              :isNewButton="checkPermission('courseschedule_store')"
                              :isColumns="true"
                              :isImportButton="true"
                              @new-button-click="showModal('create')"
                              @filter-div-status="datatable.filterStatus = $event"
                              @import-button-click="importData"
                >
                    <template v-slot:columns>
                        <div
                            class="mb-1"
                            v-for="(column, key) in datatable.columns"
                            v-bind:key="key"
                        >
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="checkPermission('courseschedule_excelexport')"
                @exportExcel="exportExcel"
            >
                <b-row>
                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"
                                :active-years="true"
                            ></academic-years-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('semester')">
                            <parameter-selectbox
                                code="semester"
                                v-model="datatable.queryParams.filter.semester"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"
                            ></faculty-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.department_code"
                            ></department-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                :department_code="datatable.queryParams.filter.department_code"
                                v-model="datatable.queryParams.filter.program_code"
                            ></program-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('teaching_staff')">
                            <staff-auto-complete
                                v-model="datatable.queryParams.filter.instructor_id"
                            ></staff-auto-complete>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete
                                v-model="datatable.queryParams.filter.course_id"
                            ></course-auto-complete>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('start_time')">
                            <b-form-input
                                type="time"
                                v-model="datatable.queryParams.filter.start_time"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('end_time')">
                            <b-form-input
                                type="time"
                                v-model="datatable.queryParams.filter.end_time"
                            >
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox
                                v-model="datatable.queryParams.filter.campus_id"
                            >
                            </campus-selectbox>
                        </b-form-group>
                    </b-col>



                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('building')">
                            <building-selectbox
                                :campus_id="datatable.queryParams.filter.campus_id"
                                v-model="datatable.queryParams.filter.building_id"
                            >
                            </building-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('classroom')">
                            <classroom-selectbox
                                :building_id="building_id"
                                v-model="datatable.queryParams.filter.classroom_id"/>
                        </b-form-group>
                    </b-col>




                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('section')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.section"
                                type="number"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal
                ref="formModal"
                size="xl"
                :onHideOnlyX="true"
                :footer="true"
                :footer-class="'justify-content-center'"
            >
                <template v-slot:CommonModalTitle>
                    <div>
                        {{
                            formProcess == "create"
                                ? toUpperCase("new")
                                : toUpperCase("edit")
                        }}
                    </div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="semester_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('semester')">
                                            <semesters-selectbox
                                                v-model="form.semester_id"
                                                :validate-error="errors[0]"
                                                :state="errors[0] ? false : null"
                                                :setActive="true"
                                            >
                                            </semesters-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="course_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('course')">
                                            <course-auto-complete
                                                v-model="form.course_id"
                                                :valueObject="{
                          value: form.course_id,
                          text: form.course_code + ' ' + form.course_name,
                        }"
                                                :validate-error="errors[0]"
                                                :state="errors[0] ? false : null"
                                            ></course-auto-complete>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="section"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('section')">
                                            <b-form-input
                                                type="number"
                                                min="1"
                                                v-model="form.section"
                                                :placeholder="$t('section')"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                                :state="errors[0] ? false : null"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="campus"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('campus')">
                                            <campus-selectbox
                                                v-model="form.campus_id"
                                                :validate-error="errors[0]"
                                                :state="errors[0] ? false : null"
                                            >
                                            </campus-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="building"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('building')">
                                            <building-selectbox
                                                :campus_id="form.campus_id"
                                                v-model="form.building_id"
                                                :validate-error="errors[0]"
                                                :state="errors[0] ? false : null"
                                            >
                                            </building-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="classroom_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('classroom')">
                                            <classroom-selectbox
                                                :building_id="form.building_id"
                                                :validateError="errors[0]"
                                                v-model="form.classroom_id"
                                                :state="errors[0] ? false : null"
                                            >
                                            </classroom-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="day"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('day')">
                                            <days-selectbox
                                                :validate-error="errors[0]"
                                                v-model="form.day"
                                                :state="errors[0] ? false : null"
                                            >
                                            </days-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="start_time"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('start_time')">
                                            <b-form-input
                                                type="time"
                                                v-model="form.start_time"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                                :state="errors[0] ? false : null"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="end_time"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('end_time')">
                                            <b-form-input
                                                type="time"
                                                v-model="form.end_time"
                                                :class="errors[0] ? 'is-invalid' : ''"
                                                :state="errors[0] ? false : null"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider
                                        name="instructor_id"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('teaching_staff')">
                                            <staff-auto-complete
                                                v-model="form.instructor_id"
                                                :valueObject="{
                          value: form.instructor_id,
                          text:
                            form.instructor_name +
                            ' ' +
                            form.instructor_surname,
                        }"
                                                :validate-error="errors[0]"
                                                :state="errors[0] ? false : null"
                                            ></staff-auto-complete>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <b-form-group :label="$t('allow_classroom_overlap')">
                                        <b-form-checkbox
                                            v-model="form.allow_classroom_overlap"
                                            :value="1"
                                        >
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>

                                <div class="col-12 col-sm-6 col-md-4">
                                    <b-form-group :label="$t('allow_instructor_overlap')">
                                        <b-form-checkbox
                                            v-model="form.allow_instructor_overlap"
                                            :value="1"
                                        >
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button variant="primary"
                                  @click="formCreate"
                                  v-if="formProcess == 'create'"/>
                        <b-button variant="primary"
                                  @click="formUpdate"
                                  label="update"
                                  v-if="formProcess == 'update'"/>
                    </b-form-group>
                </template>
            </CommonModal>
            <CommonModal size="lg" ref="importModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('import_course_schedule') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <import-form @hide="closeImportModal"></import-form>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CourseScheduleService from "@/services/CourseScheduleService";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import qs from "qs";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import DaysSelectbox from "@/components/interactive-fields/DaysSelectbox";
import importForm from "@/modules/courseSchedule/pages/importForm";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        FacultySelectbox,
        StaffAutoComplete,
        CampusSelectbox,
        BuildingSelectbox,
        ClassroomSelectbox,
        CourseAutoComplete,
        DepartmentSelectbox,
        ProgramSelectbox,
        SemestersSelectbox,
        DaysSelectbox,
        importForm,
    },
    metaInfo() {
        return {
            title: this.$t("course_schedule"),
        };
    },
    data() {
        return {
            loading: false,
            campus_id: null,
            building_id: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-search-eye-line align-middle top-minus-1 mr-3 text-muted",
                                callback: (row) => {
                                    this.showModal("update", row.id);
                                },
                                permission: 'courseschedule_update'
                            },
                            {
                                text: this.$t("delete"),
                                class:
                                    "ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted",
                                callback: (row) => {
                                    this.formDelete(row.id);
                                },
                                permission: 'courseschedule_delete'
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_code",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("course_name"),
                        field: "course_name",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("section"),
                        field: "section",
                        sortable: true,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("day"),
                        field: ("day_name"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("start_time"),
                        field: "start_time",
                        sortable: true,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("end_time"),
                        field: "end_time",
                        sortable: true,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("instructor"),
                        field: "instructor_name",
                        sortable: false,
                        hidden: false,
                        formatFn: (value, row) => {
                            return row.instructor_name + " " + row.instructor_surname;
                        },
                    },
                    {
                        label: this.toUpperCase("classroom_name"),
                        field: "classroom_name",
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("building"),
                        field: ("building_name"),
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("campus_name"),
                        field: ("campus_name"),
                        sortable: false,
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-section_id",
                    page: 1,
                    limit: 20,
                },
            },
            formProcess: null,
            formId: null,
            form: {},
        };
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    watch: {},
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                academic_year: null,
                faculty_code: null,
                instructor_id: null,
                course_id: null,
                start_time: null,
                end_time: null,
                department_code: null,
                program_code: null,
                section: null,
                classroom_id:null
            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return CourseScheduleService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        showModal(process, id = null) {
            this.formProcess = process;
            this.formId = null;
            this.formClear();
            this.$refs.form.reset();
            if (this.formProcess == "update" && id) {
                CourseScheduleService.get(id)
                    .then((response) => {
                        this.form = response.data.data;
                        this.form.allow_classroom_overlap=response.data.data.allow_classroom_overlap?1:0
                        this.form.allow_instructor_overlap=response.data.data.allow_instructor_overlap?1:0
                        this.formId = id;
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    });
            }
            this.$refs.formModal.$refs.commonModal.show();
        },

        importData() {
            this.$refs.importModal.$refs.commonModal.show()
        },
        closeImportModal() {
            this.$refs.importModal.$refs.commonModal.hide()
            this.getRows()
        },

        formClear() {
            this.form = {
                day: null,
                building_id: null,
                campus_id: null,
                section_id: null,
                course_id: null,
                course_code: "",
                course_name: "",
                section: null,
                instructor_id: null,
                instructor_name: "",
                instructor_surname: "",
                classroom_id: null,
                start_time: null,
                end_time: null,
                allow_classroom_overlap: 0,
                allow_instructor_overlap: 0,
            };
        },

        async formCreate() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.loading = true;
                let formData = {
                    ...this.form,
                };
                return CourseScheduleService.store(formData)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.getRows();
                        this.formClear();
                        this.$refs.form.reset();
                        this.$refs.formModal.$refs.commonModal.hide();
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    }).finally(() => this.loading = false);
            }
        },

        async formUpdate() {
            const isValid = await this.$refs.form.validate();
            if (isValid && this.formId) {
                this.loading = true;
                this.form.allow_instructor_overlap = this.form.allow_instructor_overlap == 1 ? 1 : 0
                this.form.allow_classroom_overlap = this.form.allow_classroom_overlap == 1 ? 1 : 0
                let formData = {
                    ...this.form,
                };

                return CourseScheduleService.update(this.formId, formData)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.getRows();
                        this.formClear();
                        this.$refs.form.reset();
                        this.$refs.formModal.$refs.commonModal.hide();
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    }).finally(() => this.loading = false);
            }
        },

        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        CourseScheduleService.del(id)
                            .then((response) => {
                                this.$toast.success(this.$t("api." + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t("api." + error.data.message));
                            });
                    }
                });
        },

        showErrors(e) {
            if (e.status == "422") {
                for (const [key, value] of Object.entries(e.data.errors)) {
                    this.$refs.form.errors[key].push(value[0]);
                }
            } else if (e.status == "406") {
                this.$toast.error(this.$t("api." + e.data.message));
            }
        },
        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            CourseScheduleService.excelExport(config)
                .then(res => this._downloadFile(res, this.$t("course_schedule") + '.xlsx'))
                .catch(err => this.showErrors(err))
        }
    },
};
</script>

